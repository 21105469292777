<template>
  <div class="AddSamplesModal">
    <span class="Title">{{ mixWB('ADD_ANALYSES') }}</span>

    <!-- Material -->
    <div
      v-if="showMaterialSamples"
      class="Section">
      <SampleSelect
        :title="materialTitle"
        :autoSampleIDs="type.getMaterialSampleList({ onlyIDs: true, onlyAutoSamples: true })"
        :manualSampleIDs="materialManualSampleIDs"
        :typeID="type.id"
        @list-update="onMaterialListUpdate" />
    </div>

    <!-- Coating -->
    <div
      class="Section"
      v-if="showCoatingSamples">
      <SampleSelect
        :title="type.getCoatingText()"
        :autoSampleIDs="type.getCoatingSampleList({ onlyIDs: true, onlyAutoSamples: true })"
        :manualSampleIDs="coatingManualSampleIDs"
        :typeID="type.id"
        :isCoating="true"
        @list-update="onCoatingListUpdate" />
    </div>

    <!-- Dust -->
    <!-- NOTE: Dust has been disabled for now -->
    <div
      class="Section"
      v-if="showDustSamples && false">
      <DustSampleSelect
        :manualSampleIDs="dustManualSampleIDs"
        :typeID="type.id"
        @list-update="onDustListUpdate" />
    </div>

    <!-- Save button -->
    <Button
      :text="mixWB('SAVE')"
      @button-click="onSaveClick"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SampleSelect from '@/components/Modals/AddSamplesModal/SampleSelect'
import Button from '@/components/Buttons/Button.vue'
import DustSampleSelect from '@/components/Modals/AddSamplesModal/DustSampleSelect'
import Type from '@/globals/javascript/models/Type'

export default {
  name: 'AddSamplesModal',
  props: {
    type: {
      type: Object,
      required: true,
    },
    kindOfSample: {
      type: String,
      required: false,
      default: '',
    },
    showAllSampleTypes: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      materialManualSampleIDs: [],
      coatingManualSampleIDs: [],
      dustManualSampleIDs: [],
    }
  },
  computed: {
    ...mapGetters([
      'screeningSamples',
    ]),
    materialTitle() {
      return `${ this.mixWB('THE_MATERIAL') } - ${ this.type.getTitle() }`
    },
    hasCoating() {
      return !!this.type.coating.coatingTypeIDs.length
    },
    isMaterialSamplesDisabled() {
      const materialSample = this.screeningSamples.find(
        (x) => x.typeID === this.type.id && x.kindOfSample === 'material',
      )

      let isMaterialSamplesDisabled = false
      if (!this.showAllSampleTypes && this.kindOfSample !== 'material') {
        isMaterialSamplesDisabled = true
      }
      else if (this.type.type.customTypeName) {
        isMaterialSamplesDisabled = true
      }
      else if (!materialSample) {
        isMaterialSamplesDisabled = false
      }
      else {
        isMaterialSamplesDisabled = materialSample.isAutoAssessment
          || materialSample.status.includes('skip-')
      }

      return isMaterialSamplesDisabled
    },
    isCoatingSamplesDisabled() {
      const hasCoating = !!this.type.coating.coatingTypeIDs.length
      const coatingSample = this.screeningSamples.find(
        (x) => x.typeID === this.type.id && x.kindOfSample === 'coating',
      )

      let isCoatingSamplesDisabled = false
      if (!this.showAllSampleTypes && this.kindOfSample !== 'coating') {
        isCoatingSamplesDisabled = true
      }
      else if (!hasCoating) {
        isCoatingSamplesDisabled = true
      }
      else if (!coatingSample) {
        isCoatingSamplesDisabled = false
      }
      else {
        isCoatingSamplesDisabled = !!coatingSample.status.includes('skip-')
      }

      return isCoatingSamplesDisabled
    },
    showMaterialSamples() {
      if (this.isMaterialSamplesDisabled) {
        return false
      }

      return true
    },
    showCoatingSamples() {
      if (this.isCoatingSamplesDisabled) {
        return false
      }

      return true
    },
    showDustSamples() {
      if (!this.showAllSampleTypes && this.kindOfSample !== 'dust') {
        return false
      }

      return true
    },
  },
  methods: {
    onMaterialListUpdate(manualSampleIDs) {
      this.materialManualSampleIDs = manualSampleIDs
    },
    onCoatingListUpdate(manualSampleIDs) {
      this.coatingManualSampleIDs = manualSampleIDs
    },
    onDustListUpdate(manualSampleIDs) {
      this.dustManualSampleIDs = manualSampleIDs
    },
    onSaveClick() {
      const materialSamplesCopy = JSON.parse(JSON.stringify(this.type.samples))
      materialSamplesCopy.manualSampleIDs = this.materialManualSampleIDs

      const coatingCopy = JSON.parse(JSON.stringify(this.type.coating))
      coatingCopy.samples.manualSampleIDs = this.coatingManualSampleIDs

      const dustCopy = JSON.parse(JSON.stringify(this.type.dust))
      dustCopy.samples.manualSampleIDs = this.dustManualSampleIDs

      // Update affected samples
      const typeCopy = new Type({
        ...this.type,
        samples: materialSamplesCopy,
        coating: coatingCopy,
        dust: dustCopy,
      })

      this.$store.dispatch('updateAffectedSamples', typeCopy)

      // Save type
      this.$store.dispatch('setTypeProps', {
        typeID: this.type.id,
        props: {
          samples: materialSamplesCopy,
          coating: coatingCopy,
          dust: dustCopy,
        },
      })

      this.$emit('close-dialog')
    },
  },
  components: {
    SampleSelect,
    Button,
    DustSampleSelect,
  },
  created() {
    this.materialManualSampleIDs = this.type.samples.manualSampleIDs
    this.coatingManualSampleIDs = this.type.coating.samples.manualSampleIDs
    this.dustManualSampleIDs = this.type.dust.samples.manualSampleIDs
  },
}
</script>

<style lang="stylus" scoped>
  .AddSamplesModal
    display block

  .Title
    modalTitle()

  .Section
    margin-bottom 20px
    .SectionTitle
      text-transform uppercase
      border-bottom 1px solid $color_grey_lighter
</style>
