<template>
  <div class="DustSampleSelect">
    <div class="TitleWrap">
      <div
        class="IconWrap">
        <TapeIcon />
      </div>
      <span class="Title">{{ mixWB('DUST_ON_THE_SURFACE') }}</span>
    </div>

    <!-- Equivalent change warning -->
    <div
      class="Warning"
      v-if="equivalentSamplesWarningTexts.length">
      <p
        v-for="text in equivalentSamplesWarningTexts"
        :key="text">{{ text }}</p>
    </div>

    <!-- This is an equivalent sample warning -->
    <div
      class="Warning"
      v-if="isEquivalentWarningTexts.length">
      <p
        v-for="text in isEquivalentWarningTexts"
        :key="text">{{ text }}</p>
    </div>

    <!-- Manual samples -->
    <div
      class="Section"
      v-if="!isEquivalentWarningTexts.length">
      <span class="SectionTitle">{{ mixWB('POSSIBLE_ANALYZES') }}</span>
      <div class="ListWrap">
        <div
          class="ManualSampleItem"
          :class="{ IsSelected: item.isSelected }"
          v-for="item in manualSamples"
          :key="item.id"
          @click="onSampleClick(item.id)">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import TapeIcon from '@/assets/svg/tape.svg?inline'
import { mapGetters } from 'vuex'

export default {
  name: 'DustSampleSelect',
  props: {
    manualSampleIDs: {
      type: Array,
      required: true,
    },
    typeID: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'sampleTypesAsArray',
      'sampleTypesAsObject',
      'screeningSamples',
    ]),
    sample() {
      return this.screeningSamples.find(
        (x) => x.kindOfSample === 'dust' && x.typeID === this.typeID,
      )
    },
    manualSamples() {
      return this.sampleTypesAsArray.reduce((prev, sample) => {
        if (!['ST-7'].includes(sample.id)) {
          return prev
        }

        prev.push({
          id: sample.id,
          text: this.mixWB(sample.translation),
          isSelected: this.manualSampleIDs.includes(sample.id),
        })
        return prev
      }, [])
    },
    equivalentSamplesWarningTexts() {
      const texts = []

      if (!this.sample?.sampleNumber) {
        return texts
      }

      const tNumbers = this.screeningSamples.reduce((prev, sample) => {
        if (
          !sample.otherScreeningID
          && sample.equivalentSampleNumber === this.sample.sampleNumber
        ) {
          prev.push(`T${ sample.equivalentNumber }`)
        }

        return prev
      }, []).sort().join(', ')

      if (!tNumbers) {
        return texts
      }

      texts.push(this.mixWB('THIS_SAMPLE_IS_REPRESENTATIVE_OF_X', [tNumbers]))

      texts.push(this.mixWB('CHANGES_WILL_X_ALSO_RECEIVE', [tNumbers]))

      return texts
    },
    isEquivalentWarningTexts() {
      const texts = []

      if (!this.sample?.equivalentNumber) {
        return texts
      }

      // Is represented by sample from another project
      if (this.sample.otherScreeningID) {
        texts.push(this.mixWB(
          'SAMPLE_REPRESENTED_BY_X_FROM_PROJECT_Y',
          [`P${ this.sample.equivalentSampleNumber }`, this.sample.getRelatedScreeningCaseNumber()],
        ))
      }
      // Is represented by a sample from this projekt
      else {
        texts.push(this.mixWB(
          'THIS_SAMPLE_IS_REPRESENTED_BY_X',
          [`P${ this.sample.equivalentSampleNumber }`],
        ))
      }

      texts.push(this.mixWB('MAKE_CHANGES_ON_REPRESENTATIVE_SAMPLE_TEXT'))

      return texts
    },
  },
  methods: {
    onSampleClick(sampleID) {
      const listCopy = [...this.manualSampleIDs]
      const index = listCopy.findIndex((x) => x === sampleID)
      if (index > -1) {
        listCopy.splice(index, 1)
      }
      else {
        listCopy.push(sampleID)
      }

      this.$emit('list-update', listCopy)
    },
  },
  components: {
    TapeIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .DustSampleSelect
    display block

  .TitleWrap
    display flex
    align-items center
    border-bottom 1px solid $color_grey_lighter
    margin-bottom 10px
    padding-bottom 2.5px
    .IconWrap
      box(16px)
      flex-center-children()
      margin-right 5px
    span
      text-transform uppercase

  .Warning
    padding 10px 30px
    font-size 0.875rem
    background-color $color_yellow_light
    margin-bottom 10px
    border-radius 3px
    p
      text-align center
      &:last-child
        padding-bottom 0

  .Section
    margin-bottom 10px
    .SectionTitle
      color $color_grey
      font-size 0.875rem
      padding-bottom 3px

  .ListWrap
    display flex
    flex-wrap wrap
    .AutoSampleItem
      margin-right 5px
      margin-bottom 5px
      padding 5px
      color $color_grey
      text-transform uppercase
      font-size 0.875rem
      background-color $color_grey_lightest
      border 1px solid $color_grey_lighter
    .ManualSampleItem
      margin-right 5px
      margin-bottom 5px
      padding 5px
      text-transform uppercase
      font-size 0.875rem
      border 1px solid $color_grey_light
      cursor pointer
      &.IsSelected
        background-color $color_purple_dark
        border 1px solid $color_purple_dark
        color #fff
</style>
